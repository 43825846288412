<template>
  <div class="carousel" @keydown="checkSlide($event)" tabindex="0">
    <slot></slot>
    <button @click.prevent="next" class="btn btn-next">
      <i class="fa fa-chevron-right"></i>
    </button>
    <button @click.prevent="prev" class="btn btn-prev">
      <i class="fa fa-chevron-left"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      slides: [],
      slideDirection: '',
    };
  },
  computed: {
    slidesLength() {
      return this.slides.length;
    }
  },
  updated() {
    this.slides = this.$children;
    this.slides.forEach((slide, index) => {
      slide.index = index;
    });
  },
  methods: {
    next() {
      this.index++;
      if (this.index >= this.slidesLength) {
        this.index = 0;
      }
      this.slideDirection = 'slide-right';
    },
    prev() {
      this.index--;
      if (this.index < 0) {
        this.index = this.slidesLength - 1;
      }
      this.slideDirection = 'slide-left';
    },
    checkSlide(event) {
      if (event.keyCode === 39) {
        this.next();
      } else if (event.keyCode === 37) {
        this.prev();
      }
    }
  }
};
</script>

<style>
.carousel {
  position: relative;
  overflow: hidden;
  width: 90%;
  max-width: 600px; /* Increased max-width */
  height: 400px; /* Increased height */
  margin: auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out;
}

.carousel:focus {
  outline: none;
}

.btn {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.8); /* Light semi-transparent background */
  color: #333; /* Darker color for better contrast */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em; /* Increase icon size */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add box shadow for better visibility */
}

.btn:hover {
  background-color: rgba(255, 255, 255, 1); /* Fully opaque on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.btn:focus {
  outline: none;
}

.btn-next {
  right: 10px;
}

.btn-prev {
  left: 10px;
}
.carousel img {
  width: 100%; /* يجعل الصورة تمتد بعرض الكاروسيل */
  height: 100%; /* يجعل الصورة تمتد بارتفاع الكاروسيل */
  max-height: 400px; /* يضمن أن الصورة لا تتجاوز ارتفاع الكاروسيل */
  object-fit: cover; /* يجعل الصورة تملأ المساحة بالكامل بدون تشويه */
  border-radius: 15px; /* يحافظ على الشكل المستدير للصور */
}
/* Ensuring responsive design */
@media (max-width: 500px) {
  .carousel {
    width: 100%;
    height: 300px; /* Adjust height for smaller screens */
  }

  .btn {
    width: 40px; /* Adjust button size for smaller screens */
    height: 40px; /* Adjust button size for smaller screens */
    font-size: 1.2em; /* Adjust icon size for smaller screens */
  }
 

}
</style>

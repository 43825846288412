/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/CustomarProperty/AddCustomerAds", item,{headers:Token})
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/Ads/GetAllAds",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Propertys', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.put("API/CustomarProperty/UpdateCustomarProperty", item,{headers:Token})
        .then((response) => {
          commit('UPDATE_Property', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit },  itemid) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get(`API/Ads/GetById/${itemid}`,{headers:Token})
      .then(response => {
        resolve(response);
       console.log(response)
       debugger;
        commit("SET_property_DATA", response.data.Data);
      })
      .catch(error => {
        reject(error);
      });
  });
  },

  removeItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.delete("API/Ads/Delete?Id="+item.Id,{headers:Token})
        .then((response) => {
          commit('REMOVE_ITEM', item.Id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  SearchPropertysData({ commit }, search) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {

      axios
        .post("API/Ads/AdsSearch", search,{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_Propertys", response.data.Data);
        })
        .catch(error => {
          reject(error);
        });
    });

  },
  GetAllStatus({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/Ads/GetAllStatus",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Status', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  ChangeStatus({ commit }, model ) {
    debugger;
    //var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
        axios
            .post(
                "/api/Ads/ChangeStatusAds" , model,
                {headers: {Authorization: 'Bearer ' + localStorage.accessToken}}
            )
            .then(response => {
                commit("uu");
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },

  GetAllAdsTypes({ commit }) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/AdsTypes/GetAll",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_AdsTypes', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

<template>
  <div class="property-container">
    
    <!-- Buttons -->
    <div class="button-group">
      <button class="contact-button" @click="makeCall(PropertyData.ContactNumber)">
        <i class="feather icon-phone-call"></i> {{ $t("Call")}}
      </button>
      <button class="whatsapp-button" @click="openWhatsApp(PropertyData.ContactNumber)">
        <i class="feather icon-message-circle"></i> {{ $t("WhatsAPP")}}
      </button>
      <button class="delete-button" @click="confirmDeleteAd">
        <i class="feather icon-trash"></i> {{ $t("DeleteAd") }}
      </button>
    </div>

    <!-- Property Details -->
    <div class="property-details">
      <h1 style="margin-bottom: 5px;">{{ PropertyData.Title }}</h1>
      <div class="property-price">{{ PropertyData.Price }} جنيه</div>
      <p>{{ PropertyData.Description }}</p>
     
    </div>

    <!-- Image Carousel -->
    <carousel>
      <carousel-slide v-for="slide in filteredSlides" :key="slide.Id">
        <img v-if="slide.AttachmentTypeID == 2"  :src="formatImageUrl(slide.UrlAttachment)"  :alt="slide" class="carousel-image">
      </carousel-slide>
    </carousel>

    <!-- Ad Details Section -->
   <!-- Ad Details Section -->
   <!-- <div class="ad-details">
      <h3>تفاصيل الإعلان</h3>
      <div class="details-grid">
        <div><strong>العنوان:</strong> {{ PropertyData.AddressAR }}</div>
      </div>
    </div> -->

    <!-- Status Selection -->
    <div class="status-section">
      <h3 style="margin-bottom: 5px;">{{ $t('Status') }}</h3>
      <v-select label="NameAr"  name="NameAr"  v-model="PropertyData.PublishStatusId" :reduce="s=>s.ID" :options="Status"
                @input="openConfirmChangeStatus(PropertyData.PublishStatusId, null)" /> 
      <div v-if="PropertyData.PublishStatusId === 4" class="deny-reason">
        <label>{{ $t("DenyReason") }}</label>
        <vs-textarea v-model="PropertyData.DenyReason" />
        <button class="submit-button" @click="openConfirmChangeStatus(4, PropertyData.DenyReason)">
          <send-icon size="1.2x"></send-icon>
        </button>
      </div>
    </div>

    <!-- Properties List -->
    <div class="properties-list">
      <h3 style="margin-bottom: 5px;">{{ $t("properties") }}</h3>
      <div class="cards-container">
        <div v-for="(card2, index) in PropertyData.AdsProperties" :key="index" class="property-card">
          <strong>{{ card2.Property.NameAr }}</strong>: {{ card2.Property.PropertySpecs[0].NameAr }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import { domain } from "@/gloabelConstant.js";
import Carousel from './Carousel.vue';
import CarouselSlide from './CarouselSlide';
import moduleProperty from "@/store/Property/moduleProperty.js";
import { SendIcon } from 'vue-feather-icons';
import { domain } from "@/gloabelConstant.js";
export default {
  data(){
    return {
    baseURL: domain,
    rowDataForDeleteAd:{},
    
    }
  },
  components: { Carousel, CarouselSlide, SendIcon },
  computed: {
    filteredSlides() {
      return this.PropertyData.AdsAttachments.filter(slide => slide.AttachmentTypeID == 2);
    },
    PropertyData() {
      return this.$store.state.PropertyList.Property;
    },
    Status() {
      debugger;
      return this.$store.state.PropertyList.Status;
    }
  },
  methods: {
    formatImageUrl(imageUrl) {
    if (!imageUrl) return `${this.baseURL}/Uploads/empty-image.png`;

    // تنظيف الرابط من علامات الاقتباس الزائدة
    imageUrl = imageUrl.replace(/^"|"$/g, ""); // إزالة " في البداية والنهاية
    imageUrl = imageUrl.replace(/%22/g, ""); // إزالة أي %22 (تمثيل لعلامة الاقتباس)

    // تصحيح أي "//" غير ضرورية
    let fixedUrl = `${this.baseURL}${imageUrl.replace(/\/{2,}/g, "/")}`;

    console.log("Formatted Image URL:", fixedUrl);
    return fixedUrl;
  },
  
  DeleteAd(data) {
      this.$store.dispatch("PropertyList/removeItem", data).then(() => {
        this.$store.dispatch("PropertyList/fetchDataListItems");
      })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("PropertyList/fetchDataListItems");
        });
    },
  confirmDeleteAd(data) {
      this.rowDataForDeleteAd = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("confirmdeleteAd"),
        acceptText: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlertDeleteAd,

      });
    },
    acceptAlertDeleteAd() {
      this.DeleteAd(this.rowDataForDeleteAd);
      this.$vs.notify({ time:8000,
        color: "success",
        title: "تم حذف الاعلان",
        text: "تم حذف الاعلان بنجاح",
      });
    },
    makeCall(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    openWhatsApp(phoneNumber) {
      const message = encodeURIComponent('Hello, I would like to contact you!');
      window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
    },
    openConfirmChangeStatus(ID, DenyReason) {
      if (ID == 4 && !DenyReason) return;
      this.$vs.dialog({
        type: "confirm",
        color: "red",
        title: this.$t("Confirm"),
        text: this.$t("ChangeStatusAd"),
        accept: () => this.ChangeStatus(ID, DenyReason),
        cancel: () => {
          this.$store.dispatch("PropertyList/GetItemByID", this.$route.params.Id)
            .then(re => this.PropertyData.PublishStatusId = re.data.Data.PublishStatusId);
        },
        acceptText: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
      });
    },
    ChangeStatus(ID, DenyReason) {
      this.$store.dispatch("PropertyList/ChangeStatus", {
        AdsID: this.$route.params.Id,
        StatuseID: ID,
        DenyReason
      });
    }
  },
  created() {
    if (!moduleProperty.isRegistered) {
      this.$store.registerModule("PropertyList", moduleProperty);
      moduleProperty.isRegistered = true;
    }
    this.$store.dispatch("PropertyList/GetItemByID", this.$route.params.Id);
    this.$store.dispatch("PropertyList/GetAllStatus");
  }
}
</script>

<style scoped>
.property-container {
  padding: 20px;
  max-width: 1100px;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}
.status-section {
  padding-bottom: 25px;
}
.contact-button, .whatsapp-button,.delete-button {
  padding: 12px 25px;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.contact-button { background-color: #007bff; }
.whatsapp-button { background-color: #25D366; }
.delete-button {
  background: #c94552; /* لون أحمر أغمق عند المرور */
}
.property-details {
  text-align: center;
  padding: 15px;
}

.property-price {
  font-weight: bold;
  font-size: 22px;
  color: #d9534f;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
}
.sclass{
  background-color: white !important;
}
.properties-list .cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.property-card {
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
</style>

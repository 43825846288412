/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Propertys.unshift(item)
  },
  SET_Propertys(state, Propertys) {
    debugger;
    try {
      console.log("📢 Vuex Propertys Updated:", Propertys);
      state.Propertys = Propertys;

    } catch (error) {
      console.error("Failed to parse Properties data JSON:", error);
      state.Propertys = [];
    }
  },
  UPDATE_Property(state, Property) {
    debugger
    const PropertyIndex = state.countries.findIndex((p) => p.ID == Property.Id)
    Object.assign(state.Propertys[PropertyIndex], Property)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.Propertys.findIndex((p) => p.ID == itemId)
      state.Propertys.splice(ItemIndex, 1)
  },

  SET_property_DATA(state, PropertyData){
    debugger;
    state.Property =PropertyData;
  },

  SET_Status(state, Status) {
    debugger;
    try {

      state.Status = Status;

    } catch (error) {
      console.error("Failed to parse Properties data JSON:", error);
      state.Status = [];
    }
  },
  SET_AdsTypes(state, AdsTypes) {
    debugger;
    try {

      state.AdsTypes = AdsTypes;

    } catch (error) {
      console.error("Failed to parse Properties data JSON:", error);
      state.Status = [];
    }
  },
}
